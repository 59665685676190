import { Action } from "redux"
import { all, call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { IProject } from "@api/schema"
import { IFormikActions, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction, updateModelSuccessAction } from "@redux/helper/actions"
import { showErrorsInTestEnvironment } from "@redux/helper/sagas"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { EntityType } from "@redux/reduxTypes"
import { SubmissionError } from "@services/submissionError"

/**
 * This enum defines the usecases around the "classification of a project".
 * @todo warum ist das kein "Project usecase" ?
 * @see https://futureprojects.atlassian.net/browse/FCP-1818
 */
export enum ClassifyProjectUsecase {
  /**
   * classify a project (by a program perspective)
   */
  ClassifyProject = "_usecase_classify_project",
}

export interface IClassifyProjectAction extends Action<ClassifyProjectUsecase> {
  actions: IFormikActions
  project: IProject
  type: ClassifyProjectUsecase.ClassifyProject
}

export const classifyProjectAction = (project: IProject, actions: IFormikActions): IClassifyProjectAction => ({
  actions,
  project,
  type: ClassifyProjectUsecase.ClassifyProject,
})

export function* classifyProjectWatcherSaga(): any {
  yield all([
    takeEvery(ClassifyProjectUsecase.ClassifyProject, withCallback(classifyProjectSaga)),
  ])
}

function* classifyProjectSaga(action: IClassifyProjectAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey))

    const project: IProject = yield call(apiClient.classifyProject, action.project)

    yield put(updateModelSuccessAction(EntityType.Project, project))

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Project, usecaseKey, project))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess, project)
    }

    return project
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    showErrorsInTestEnvironment("classifyProjectSaga", errorMessage, action, err)

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}

// #endregion
