import { Action } from "redux"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { putWait, withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { IMotivationAndSkills, IProject, IUser, IUserObjectRole, IUserProjectRole, TenantRole, UserRole } from "@api/schema"
import { ActionRequestType, IActionRequest, IProjectMemberApplication } from "@api/schema/action-requests"
import { INNER_TEAM_ROLES } from "@basics/pageAccess"
import {
  createModelSuccessAction,
  IFormikActions,
  newLoadCollectionAction,
  newSingleEntityUsecaseRequestRunningAction,
  newSingleEntityUsecaseRequestSuccessAction,
  usecaseRequestRunningAction,
  usecaseRequestSuccessAction
} from "@redux/helper/actions"
import { showErrorsInTestEnvironment } from "@redux/helper/sagas"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { EntityType } from "@redux/reduxTypes"
import { getCurrentUser } from "@redux/saga/currentUser"
import { ActionRequestHelper } from "@services/actionRequestHelper"
import { usecaseKeyForLoadCollection } from "@services/hooks/useEntityCollection"
import { SubmissionError } from "@services/submissionError"
import { getUserObjectRoleByObjectIRI, hasUOROnAnyObjectRole } from "@services/userObjectRolesHelper"

import { selectMyUserObjectRoles } from "./userObjectRoles"

// #region usecases
/** *************************************************************************************************
 * This enum defines the usecases around the "action requests".
 *
 * @todo multi add usecase "view", which is needed for invitations
 */
export enum ActionRequestsUsecase {
  /**
   * Accept an action request.
   */
  Accept = "_usecase_accept_action_requests",
  /**
   * Reject an action request.
   */
  Reject = "_usecase_reject_action_requests",
  /**
   * Withdraw an action request.
   */
  Withdraw = "_usecase_withdraw_action_requests",
  /**
   * Delete the action request by the receiver or the sender.
   */
  Delete = "_usecase_delete_action_requests",
  /**
   * PlatfromActionRequests
   */
  PlatformActionRequests = "_usecase_platform_action_requests"
}

// #endregion

// #region redux actions

/**
 * Used when an action request is accepted/rejected/withdrawn.
 */
export interface IActionRequestReply {
  /** This message represents the reply message to an action request. */
  message?: string
}

/**
 * Notation specified by the api.
 *
 * Im Backend gibt es diese "zusätzlichen" Rollen nur für die Projektmemberships, welche
 * sich von den MembershipsRole unterscheiden.
 * Der Grund ist, die anderen MembershipsRole haben ihre Werte basierend auf den Namen/"@type" von den
 * UserProjectRoles, denn im Backend gibt es die Klasse ProjectCoordinator, welche eine userObjectRole ist,
 * und durch den Namen der klasse hat der "@type" den Wert "projectcoordinator".
 * @todo multi Ein Wunsche wäre es, dass das Backend die Werte des Enums mit den Klassennamen der UserObjectRoles
 * angleicht oder welche Argumente sprechen dagegen?
 * @see https://futureprojects.atlassian.net/browse/FCP-1786
 */
export enum UpdateMembershipRole {
  Coordinator = 'coordinator',
  Observer = 'observer',
  Planner = 'planner',
}

/**
 * Action type for accepting a project member application.
 */
export interface IMemberRoleInput extends IActionRequestReply {
  role: UpdateMembershipRole
}

/**
 * General action to accept/reject/withdraw an ActionRequest.
 * Note: The token is only used by certain ActionRequests, currently only used by
 * invitiations in the usecases accept and reject the invitations.
 */
interface IActionRequestReplyAction<
  ActionRequestReplyInputType extends IActionRequestReply
> extends Action<ActionRequestsUsecase> {
  actions: IFormikActions
  actionRequest: IActionRequest
  reply: ActionRequestReplyInputType
  token?: string
}

/**
 * Action type for deleting an action request.
 */
interface IActionRequestDeleteAction extends Action<ActionRequestsUsecase> {
  actions: IFormikActions
  actionRequest: IActionRequest
}

export const acceptActionRequestAction = <ActionRequestReply extends IActionRequestReply = IActionRequestReply>(
  actionRequest: IActionRequest,
  reply: ActionRequestReply,
  actions: IFormikActions,
  token?: string
): IActionRequestReplyAction<ActionRequestReply> => ({
  actionRequest,
  actions,
  reply,
  token,
  type: ActionRequestsUsecase.Accept
})

export const rejectActionRequestAction = (
  actionRequest: IActionRequest,
  reply: IActionRequestReply,
  actions: IFormikActions,
  token?: string
): IActionRequestReplyAction<IActionRequestReply> => ({
  actionRequest,
  actions,
  reply,
  token,
  type: ActionRequestsUsecase.Reject
})

export const withdrawActionRequestAction = (
  actionRequest: IActionRequest,
  reply: IActionRequestReply,
  actions: IFormikActions
): IActionRequestReplyAction<IActionRequestReply> => ({
  actionRequest,
  actions,
  reply,
  type: ActionRequestsUsecase.Withdraw
})

export const deleteActionRequestAction = (
  actionRequest: IActionRequest,
  actions: IFormikActions
): IActionRequestDeleteAction => ({
  actionRequest,
  actions,
  type: ActionRequestsUsecase.Delete
})
// #endregion

// #region saga
/**
 * Saga watcher method that is registered in redux/sagas/index rootSaga
 */
export function* actionRequestWatcherSaga(): any {
  yield takeLatest(ActionRequestsUsecase.Accept, withCallback(actionRequestSaga))
  yield takeLatest(ActionRequestsUsecase.Reject, withCallback(actionRequestSaga))
  yield takeLatest(ActionRequestsUsecase.Withdraw, withCallback(actionRequestSaga))
  yield takeLatest(ActionRequestsUsecase.Delete, withCallback(actionRequestSaga))
  yield takeLatest(ProjectMemberApplicationUsecase.Create, withCallback(createProjectMemberApplicationSaga))
}

/**
 * the saga return true if it is successful, but to mark the return value
 * this constant is used
 */
const OPERATION_SUCCESSFUL = true

/**
 * General saga, that considers all usecases.
 */
function* actionRequestSaga<ActionRequestReplyInputType extends IActionRequestReply>(action: IActionRequestReplyAction<ActionRequestReplyInputType>) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type

  const currentUser: IUser = yield call(getCurrentUser)

  const userObjectRoles: IUserObjectRole[] = yield select(selectMyUserObjectRoles)
  const projectRole = action.actionRequest.relatedEntity !== ""
    && getUserObjectRoleByObjectIRI(userObjectRoles, action.actionRequest.relatedEntity) as IUserProjectRole

  try {
    yield put(usecaseRequestRunningAction(usecaseKey))

    switch (usecaseKey) {
      case ActionRequestsUsecase.Accept:
        // Accept is a special usecase, b/c with accepting an action request,
        // a new object could be created, which have to be integrated in the redux store.
        // E.g. by accepting a project member application an IUserProjectRole will be created.
        switch (action.actionRequest["@type"]) {
          case ActionRequestType.ProjectMemberApplication:
            const resultAccept: IUserProjectRole = yield call(
              apiClient.acceptProjectMemberApplication,
              // It is safe to cast, b/c of the check on '@type'.
              action.actionRequest as IProjectMemberApplication,
              // It is safe to cast, b/c of the check on '@type'.
              action.reply as unknown as IMemberRoleInput
            )
            yield put(createModelSuccessAction(EntityType.UserObjectRole, resultAccept))
            break
          default:
            yield call(
              apiClient.acceptActionRequest,
              action.actionRequest,
              action.reply,
              action.token
            )
            break
        }
        break
      case ActionRequestsUsecase.Reject:
        yield call(
          apiClient.rejectActionRequest,
          action.actionRequest,
          action.reply,
          action.token
        )
        break
      case ActionRequestsUsecase.Withdraw:
        yield call(
          apiClient.withdrawActionRequest,
          action.actionRequest,
          action.reply
        )
        break
      case ActionRequestsUsecase.Delete:
        if (currentUser["@id"] === action.actionRequest.sender) {
          yield call(
            apiClient.deleteActionRequestBySender,
            action.actionRequest,
          )
        } else if (projectRole.object["@id"] === action.actionRequest.receiver) {
          yield call(
            apiClient.deleteActionRequestByReceiver,
            action.actionRequest,
          )
          break
        }
    }

    // #region update ActionRequest
    // Currently it is only possible to load an ActionRequests over a Collection-Call.
    // And these Collection-Calls offer no filtering.
    // Therefor, there exist only one usecase.
    // Only the invitations are an exception, which provide a single-call.
    // A different strategy would to evaluate the result of type IOperationResult and
    // depending on the result, the redux store would be updated. But that's a completely new strategy.
    /** @see https://futureprojects.atlassian.net/browse/FCP-1768?focusedCommentId=15443 */

    // Reload the user action requests, b/c the action request was updated.
    if (ActionRequestHelper.senderOrReceiverIsOfEntityType(action.actionRequest, EntityType.User)) {
      yield putWait(newLoadCollectionAction(
        EntityType.ActionRequest,
        null,
        usecaseKeyForLoadCollection(null, null, currentUser["@id"]),
        currentUser["@id"],
        true /* loadAll */
      ))
    }

    // If the user has the right to retrieve the 'project action requests',
    // reload them, b/c the action request was updated.
    if (ActionRequestHelper.senderOrReceiverIsOfEntityType(action.actionRequest, EntityType.Project)
      && INNER_TEAM_ROLES.includes(projectRole?.["@type"])
      && action.actionRequest.relatedEntity !== ""
    ) {
      yield putWait(newLoadCollectionAction(
        EntityType.ActionRequest,
        null,
        usecaseKeyForLoadCollection(null, null, action.actionRequest.relatedEntity),
        action.actionRequest.relatedEntity,
        true /* loadAll */
      ))
    }

    // Reload all platform action requests, if the current authenticated user is a platform manager or an account manager
    // and if the action request is a platform action request.
    if (ActionRequestHelper.isPlatformActionRequest(action.actionRequest)
      && (currentUser.roles.includes(UserRole.PlatformManager) || hasUOROnAnyObjectRole(userObjectRoles, TenantRole.Accountmanager))) {
      yield putWait(newLoadCollectionAction(
        EntityType.ActionRequest,
        null,
        ActionRequestsUsecase.PlatformActionRequests,
        null,
        true /* loadAll */
      ))
    }
    // #endregion

    yield put(usecaseRequestSuccessAction(usecaseKey, OPERATION_SUCCESSFUL))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess)
    }

    return OPERATION_SUCCESSFUL
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("actionRequestSaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.ActionRequest, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}

// #endregion

// #region project member application

export enum ProjectMemberApplicationUsecase {
  Create = "_usecase_create_project_member_application"
}

interface IApplyForProjectMembershipAction extends Action<ProjectMemberApplicationUsecase> {
  actions: IFormikActions
  project: IProject
  motivationAndSkills: IMotivationAndSkills
}

export const createProjectMemberApplicationAction = (
  motivationAndSkills: IMotivationAndSkills,
  project: IProject,
  actions: IFormikActions
): IApplyForProjectMembershipAction => ({
  actions,
  motivationAndSkills,
  project,
  type: ProjectMemberApplicationUsecase.Create
})

export function* createProjectMemberApplicationSaga(action: IApplyForProjectMembershipAction): Generator<any, IProjectMemberApplication, any> {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  try {
    // if this request has a specific use case that triggers it: signalize that this use case is starting
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.ActionRequest, ProjectMemberApplicationUsecase.Create))

    // send request to the backend api
    const application: IProjectMemberApplication = yield call(apiClient.createProjectMemberApplication, action.project, action.motivationAndSkills)

    // signal to the use-case triggering application component: the creation was successfull
    yield put(createModelSuccessAction(EntityType.ActionRequest, application))

    // signal a INewUsecaseRequestAction
    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.ActionRequest, ProjectMemberApplicationUsecase.Create, application))

    if (onSuccess) {
      yield call(onSuccess, application)
    }
    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return application
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("createProjectMemberApplicationSaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }
    // signal a INewUsecaseRequestAction
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.ActionRequest, ProjectMemberApplicationUsecase.Create, errorMessage))

    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}
// #endregion
