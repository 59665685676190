import { Action } from "redux"
import { call, put, takeEvery } from "redux-saga/effects"
import { putWait, withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { iriFromEntityTypeAndId } from "@api/entityTypeEndpointDefinitions"
import { IProject, IUser, IUserObjectRole, IUserProjectRole } from "@api/schema"
import { TEAM_ROLES } from "@basics/pageAccess"
import { stringToInt } from "@basics/util-importless"
import { addNotificationAction } from "@redux/actions/notifications"
import {
  deleteModelSuccessAction,
  IFormikActions,
  newLoadCollectionAction,
  newSingleEntityUsecaseRequestRunningAction,
  newSingleEntityUsecaseRequestSuccessAction,
  updateModelSuccessAction,
} from "@redux/helper/actions"
import { showErrorsInTestEnvironment } from "@redux/helper/sagas"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { AppState } from "@redux/reducer"
import { selectCurrentUserId } from "@redux/reducer/auth"
import { selectCollectionUsecaseState } from "@redux/reducer/data"
import { EntityType } from "@redux/reduxTypes"
import { getCurrentUser } from "@redux/saga/currentUser"
import { usecaseKeyForLoadCollection } from "@services/hooks/useEntityCollection"
import { prefixedKey } from "@services/i18n"
import { SubmissionError } from "@services/submissionError"
import { filterRoles } from "@services/userObjectRolesHelper"

// #region usecases and actions
/**
 * Defines all usecases for a UserObjectRole.
 *
 * Explanation for the differentiation between Update and ChangeRole usecase:
 * In the API/Backend is the MemberRole is not a property of one general class. It is a class itself.
 * For each class/MemberRole exists an update endpoint, which can update defined properties of the class itself,
 * which is motivation and skills. Therefor in order to change the MemberRole, the class has to change, that's why
 * there is an extra endpoint on each MemberRole/class (UserProjectRole) to change the role.
 * E.g. that's why it can happen that the client has to do 2 calls, if a project coordinator changes his role and his motivation/skills.
 */
export enum UserObjectRoleUsecase {
  /** Delete an user object role. */
  Delete = "_usecase_delete_user_object_role",
  /** Update motivation and/or skills of an UserProjectRole. */
  Update = "_usecase_update_user_project_role",
  /**
   * Change member role of an UserProjectRole.
   * Only a project coordinator can change the role.
   */
  ChangeRole = "_usecase_change_member_role_user_project_role",
}

/**
 * Usecase key for a single update or delete action.
 * Did not use the function usecaseKeyForUpdateModel or usecaseKeyForDeleteModel,
 * b/c the user object roles having an own saga, which needs to be triggered by user object roles redux action using
 * an unique usecaseKey.
 */
export const generateUsecaseKeyForUORAction = (usecase: UserObjectRoleUsecase, userObjectRole: IUserObjectRole): string =>
  usecase + userObjectRole?.["@id"]

interface IUserObjectRoleAction extends Action<UserObjectRoleUsecase> {
  actions: IFormikActions
  userObjectRole: IUserObjectRole
}

interface IUserProjectRoleUpdateAction extends IUserObjectRoleAction {
  userObjectRole: IUserProjectRole
  actions: IFormikActions
}

export const deleteUserObjectRoleAction = (
  userObjectRole: IUserObjectRole,
  actions: IFormikActions,
): IUserObjectRoleAction => ({
  actions,
  userObjectRole,
  type: UserObjectRoleUsecase.Delete
})

export const updateUserObjectRoleAction = (
  actions: IFormikActions,
  userObjectRole: IUserProjectRole,
): IUserProjectRoleUpdateAction => ({
  actions,
  userObjectRole,
  type: UserObjectRoleUsecase.Update
})

export const changeRoleOfUserObjectRoleAction = (
  actions: IFormikActions,
  userObjectRole: IUserProjectRole,
): IUserProjectRoleUpdateAction => ({
  actions,
  userObjectRole,
  type: UserObjectRoleUsecase.ChangeRole
})

// #endregion

// #region watcher saga and saga
export function* userObjectRolesWatcherSaga(): any {
  yield takeEvery(UserObjectRoleUsecase.Delete, withCallback(userObjectRoleSaga))
  yield takeEvery(UserObjectRoleUsecase.Update, withCallback(userObjectRoleSaga))
  yield takeEvery(UserObjectRoleUsecase.ChangeRole, withCallback(userObjectRoleSaga))
}

export function* userObjectRoleSaga(action: IUserObjectRoleAction): Generator<any, boolean, any> {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  const usecaseKey = generateUsecaseKeyForUORAction(action.type, action.userObjectRole)

  const currentUser: IUser = yield call(getCurrentUser)

  try {
    // signal a INewUsecaseRequestAction
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.UserObjectRole, usecaseKey))

    switch (action.type) {
      case UserObjectRoleUsecase.Delete:
        const deleteAction = action
        yield call(apiClient.deleteEntity, deleteAction.userObjectRole)
        yield put(deleteModelSuccessAction(EntityType.UserObjectRole, deleteAction.userObjectRole))
        break
      case UserObjectRoleUsecase.Update:
        const updateAction: IUserProjectRoleUpdateAction = action as IUserProjectRoleUpdateAction
        const updatedUOR = yield call(
          apiClient.updateUserProjectRole,
          updateAction.userObjectRole,
        )
        yield put(updateModelSuccessAction(EntityType.UserObjectRole, updatedUOR))
        yield put(addNotificationAction(
          prefixedKey("user-object-roles-operations", "projectRoles.message.editMembership"),
          "success"
        ))
        break
      case UserObjectRoleUsecase.ChangeRole:
        const changeRoleAction: IUserProjectRoleUpdateAction = action as IUserProjectRoleUpdateAction
        yield call(
          apiClient.changeRoleOfUserProjectRole,
          changeRoleAction.userObjectRole
        )

        // Reload the user project roles of the project itself (projectmemberships).
        yield putWait(newLoadCollectionAction(
          EntityType.UserObjectRole,
          null,
          usecaseKeyForLoadCollection(null, null, changeRoleAction.userObjectRole.object["@id"]),
          changeRoleAction.userObjectRole.object["@id"],
          true /* loadAll */
        ))

        if (currentUser["@id"] === changeRoleAction.userObjectRole.user["@id"]) {
          // Reload only the current user object roles, if the current user updated his own
          // user object role.
          yield putWait(newLoadCollectionAction(
            EntityType.UserObjectRole,
            null,
            usecaseKeyForLoadCollection(null, null, currentUser["@id"]),
            currentUser["@id"],
            true /* loadAll */
          ))
          yield put(addNotificationAction(
            prefixedKey("user-object-roles-operations", "projectRoles.message.changeMyRole"),
            "success"
          ))
        } else {
          yield put(addNotificationAction(
            prefixedKey("user-object-roles-operations", "projectRoles.message.changeRole"),
            "success",
            null,
            {
              username: changeRoleAction.userObjectRole.user.username
            }
          ))
        }
        break
    }

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.UserObjectRole, usecaseKey, action.userObjectRole))

    if (onSuccess) {
      yield call(onSuccess)
    }
    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return true
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("userObjectRoleSaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }
    // signal a INewUsecaseRequestAction
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.UserObjectRole, usecaseKey, errorMessage))

    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return false
  }
}

// #endregion

// #region helper
// @todo multi: die hiesigen Funktionen könnten in den userObjectRolesHelper wandern

/**
 * gets all UserObjectRoles of the user with the given id
 * from the request that fetched the UORs from the users endpoint
 *
 * @param state the current AppState
 * @returns the found IUserProjectRole as array
 */
export const selectUserObjectRoles = (state: AppState, userId: number): IUserObjectRole[] => {
  if (!userId) {
    return []
  }

  const userObjectRoles: IUserProjectRole[] = selectCollectionUsecaseState(
    state,
    EntityType.UserObjectRole,
    usecaseKeyForLoadCollection(null, null, iriFromEntityTypeAndId(EntityType.User, userId))
  )
    .getItems<IUserProjectRole>()

  return userObjectRoles
}

/**
 * gets all UserObjectRoles of the currentUser from the state
 *
 * @param state the current AppState
 * @returns the found IUserProjectRole as array
 */
export const selectMyUserObjectRoles = (state: AppState): IUserObjectRole[] =>
  selectUserObjectRoles(state, selectCurrentUserId(state))

/**
 * gets all UserObjectRoles of the currentUser from the state, which match one of the MembershipRoles
 * to get all project memberships of the user
 *
 * @param state the current AppState
 * @returns the found IUserProjectRole as array
 */
export const selectMyMemberships = (state: AppState): IUserProjectRole[] => {
  const userId = selectCurrentUserId(state)
  if (!userId) {
    return []
  }

  const userProjectRoles: IUserProjectRole[] =
    filterRoles<IUserProjectRole>(selectMyUserObjectRoles(state), TEAM_ROLES)

  return userProjectRoles
}


/**
 * Selects this project from the user's UserObjectRoles that has the given slug or id.
 *
 * NOTE: projects from UserObjectRoles are stubs only!
 *
 * @param state the current AppState
 * @param slugOrId slug or id of the searched project the user has a membership relation to
 * @returns the project the user is member with the given slug or id, or null if no project matches id or slug
 */
export const selectProjectStubBySlugOrIdFromCurrentUsersObjectRoles =
  (state: AppState, slugOrId: string | number): IProject | null => {
    if (!slugOrId) {
      return null
    }
    const myMemberships = selectMyMemberships(state)

    let membership: IUserProjectRole = null

    // is slugOrId convertable to a number: than the id is already given
    const id = stringToInt(slugOrId as string)
    // if the id is not given search for the slug
    if (id) {
      membership = myMemberships.find(ms => ms.object.id === id)
    } else {
      membership = myMemberships.find(ms => ms.object.slug === slugOrId)
    }

    // assume: membership.project is not an IRI but a project stub
    return membership?.object ?? null
  }
// #endregion