import { isOfEntityType } from "@api/entityTypeEndpointDefinitions"
import { IModel } from "@api/schema"
import { IActionRequest, IPlatformActionRequest, SystemMailbox } from "@api/schema/action-requests"
import { EntityType } from "@redux/reduxTypes"


export class ActionRequestHelper {

  /**
   * Verifies, if an actionRequest belongs to a certain entityType, which means the sender or the receiver
   * is from the given EntityType (filtered ActionRequest).
   * This is used when an ActionRequest was updated to reload the related ActionRequest,
   * which means reloading the ActionRequest belonging to the same EntityType.
   *
   * @see IAbstractIModelActionRequest in schema/action-requests for more informations about filtered ActionRequests.
   */
  public static senderOrReceiverIsOfEntityType(
    actionRequest: IActionRequest,
    entityType: EntityType.Challenge | EntityType.Project | EntityType.Program | EntityType.User
  ): boolean {
    return isOfEntityType(actionRequest.receiverObject as IModel, entityType) || isOfEntityType(actionRequest.senderObject as IModel, entityType)
  }

  /**
   * Type guard function, which checks, if the ActionRequest is a platform ActionRequest.
   *
   * @todo multi as soon as ActionRequests exist, where the sender is a SystemMailbox (ManagerInvitations), use the ‘||’ condition.
   * Based on the current client implementation, typescript do not allow the or branch
   */
  public static isPlatformActionRequest = (actionRequest: IActionRequest): actionRequest is IPlatformActionRequest =>
    actionRequest.receiver === SystemMailbox // || actionRequest.sender === SystemMailbox
}